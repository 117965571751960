<template>
  <section  ref="main" class="case-image d-flex justify-center align-center">
    <section :style="'width:'+imageWidth +'px'" ref="case-image-container" class="case-image-container d-flex flex-column" >
      <v-btn small outlined color="white" class="mb-2 close-btn"  @click="$emit('close')" alt="close" ><v-icon color="black">mdi-close</v-icon></v-btn>
      <img @load="imgloaded" ref="planche-img" class="img-only" :src="image" alt="">
    </section>
  </section>
</template>
<script>
import Hammer from 'hammerjs'
export default {
  props: {
    image: {
      type: String,
      default: ''
    },
  },
  data: () => ({
    imageWidth: null
  }),
  mounted () {
    this.enableSwipe()
  },
  methods: {
    enableSwipe () {
      const self = this;
      var Swipe = new Hammer.Swipe()
      const mc_main = new Hammer.Manager(this.$refs['main'])
      mc_main.add(Swipe)
      mc_main.on('swipeleft', function(e) {
        self.$emit('next')
      })
      mc_main.on('swiperight', function(e) {
        self.$emit('previous')
      })
    },
    imgloaded () {
      const imageWidth = this.$refs['planche-img'].naturalWidth
      const imageHeight = this.$refs['planche-img'].naturalHeight
      const areaWidth = this.$refs['case-image-container'].offsetWidth
      const areaHeight = this.$refs['case-image-container'].offsetHeight
      const imageRatio = imageWidth / imageHeight

      if (imageRatio >= areaWidth / areaHeight) {
        // longest edge is horizontal
        this.imageWidth = areaWidth
      } else {
        // longest edge is vertical
        this.imageWidth = areaHeight * imageRatio
      }
    }
  },
}
</script>

<style lang="scss">
  .close {
    position: absolute;
    top: 30px;
    right: 30px;
  }
    .img-only {
        // width: 40%;
        @media screen and (max-width: 500px)  {
          width: 100%;
        }

    }
</style>
