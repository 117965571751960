var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"planche"},[_c('section',{staticClass:"planche-img-container"},[_c('img',{staticClass:"planche-img",attrs:{"src":_vm.planche.url,"alt":""}}),_vm._l((_vm.planche.planche_zones),function(zone,i){return _c('PlancheZone',{key:zone.id,attrs:{"zone":zone,"disabled":_vm.index === 1
          ? _vm.tutorial && i > 0
            ? true
            : false
          : _vm.tutorial
          ? true
          : false,"tutorial":_vm.tutorial},on:{"zone-clicked":zone => _vm.$emit('zone-clicked', zone)}})})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }