<template>
    <v-container :class="'mot-expert '+type">
        <h4>Le mot de l'expert</h4>
        <p>{{message}}</p>
    </v-container>
</template>
<script>
export default {
  props: ['type', 'message']
}
</script>
