<template>
    
    <div ref="main" class="modal-wrap d-flex justify-center align-center">
      <section class="modal game-modal ma-auto d-block">
        <img @click='closeModal' class='close' src="@/assets/images/icons/close-black.svg" alt="Fermer">
      <h3>Quiz</h3>
     <simplebar data-simplebar-auto-hide="true">

        <div v-if='current' class="game-content">

            <!-- <transition name="fade"> -->
                <Gap @addGameLog='handleAddGameLog' :style="'z-index:'+currentIndex*10" :data='current' v-if='current.gameType.slug === "drag_drop"' @next='handleNext' v-show="!exit" :key="refreshGame"/>
                <Memory @addGameLog='handleAddGameLog' @next='handleNext' :style="'z-index:'+currentIndex*10" :data='current' v-if='current.gameType.slug === "memory" ' v-show="!exit" :key="refreshGame"/>
                <QCM @next='handleNext' @addGameLog='handleAddGameLog' :style="'z-index:'+currentIndex*10" :data='current' v-if='current.gameType.slug === "quiz_simple" ' v-show="!exit" :key="refreshGame"/>
                <Quizz @addGameLog='handleAddGameLog' @next='handleNext' :style="'z-index:'+currentIndex*10" :data='current' v-if='current.gameType.slug === "true_false" ' v-show="!exit" :key="refreshGame"/>
                <Reorder @addGameLog='handleAddGameLog' @next='handleNext' :style="'z-index:'+currentIndex*10" :data='current' v-if='current.gameType.slug === "reorder" ' v-show="!exit" :key="refreshGame"/>
                <Confirm v-if='exit' @exit='$emit("close")' @continue='exit=false'/>
            <!-- </transition> -->

        </div>
  </simplebar>


        <!-- <div class="game-header d-flex align-center">
          <div class="index_chap d-flex justify-center align-center" :style="'background:' + currentChapter.color">
            <span>0{{currentChapter.number}}</span>

          </div>
            <span class="text">{{currentChapter.title}}</span>
            <h4 class="consigne" v-if='current'>{{consigne}}</h4>
        </div> -->
    </section>
    </div>
</template>
<script>
import Gap from './Gap'
import Memory from './Memory/Memory'
import QCM from './QCM/QCM'
import Quizz from './Quizz/Quizz'
import Reorder from './Reorder'
import Confirm from './ConfirmLeaveGame'
import { mapGetters } from 'vuex'
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
import Hammer from "hammerjs"

export default {
  components: {
    Gap,
    Memory,
    QCM,
    Quizz,
    Reorder,
    Confirm,
    simplebar
  },
  props: ['data'],
  data () {
    return {
      current: null,
      currentIndex: 0,
      score: 0,
      gameDone: false,
      exit: false,
      refreshGame: 0
    }
  },
  mounted () {
    this.current = this.data[this.currentIndex]
    this.enableSwipe() 

  },
  methods: {
    enableSwipe() {
      let self = this;
      var Swipe = new Hammer.Swipe()
      var mc_main = new Hammer.Manager(this.$refs['main'])
      mc_main.add(Swipe)
        mc_main.on('swipeleft', function(e) {
         self.$emit('next')
      })
      mc_main.on('swiperight', function(e) {
        self.$emit('previous')
      })
    },
    async handleAddGameLog ({data, game_id}) {
      const logType = await this.$store.dispatch('Logs/getLogTypeGame')
      await this.$store.dispatch('Logs/insertLog', { data, user_id: this.user.id, data_key: game_id, log_type_id: logType.id })
    },
    handleNext () {
      if (this.currentIndex === this.data.length - 1) {
        this.gameDone = true
        this.refreshGame++
        this.$emit('next-zone')
      } else {
        this.currentIndex++
        this.current = this.data[this.currentIndex]
        this.refreshGame++
      }
    },
    closeModal () {
      if(this.gameDone) this.$emit("close")
      else this.exit = true
      this.refreshGame++
    }
  },
  computed: {
    ...mapGetters('Chapter', ['currentChapter']),
    ...mapGetters('Auth', ['user']),
    consigne () {
      let message
      if (this.current.instruction) {
        message = this.current.instruction
      } else {
        switch (this.current.gameType.slug) {
          case 'true_false':
            message = 'Ces affirmations sont-elles correctes?'
            break
          case 'reorder':
            message = 'Dans quel ordre ces actions doivent-elles être effectuées ?'
            break
          case 'quiz_simple':
            message = 'Cochez la ou les bonne(s) réponse(s)'
            break
          case 'drag_drop':
            message = 'Replacez les mots au bon endroit dans le texte.'
            break
          default:
            message = 'Consigne non renseignée'
        }
      }
      return message
    }
  }
}
</script>
