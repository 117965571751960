<template>
    <section class="game-quizz">
        <Question :isLast='isLast' @next='handleNext' @nextQuestion='handleNextQuestion' @update-score="updateScore" v-if='current' :data="current"/>
    </section>
</template>
<script>
import Question from './Quizz_Question'
import { handleScore } from '@/mixins/handleScore.js'

export default {
  components: {
    Question
  },
  props: ['data'],
  mixins: [handleScore],
  data () {
    return {
      current: null,
      currentIndex: 0,
      results: { right: 0, wrong: 0, total: 0 },
    }
  },
  mounted () {
    this.current = this.data.questions[this.currentIndex]
    this.results.total = this.data.questions.length
  },
  methods: {
    handleNext () {
      this.$_handleScore()
      this.$emit('next')
    },
    handleNextQuestion () {
      this.currentIndex++
      this.current = this.data.questions[this.currentIndex]
    },
    updateScore (isRightAnswer) {
      if (isRightAnswer) this.results.right++
      else this.results.wrong++
    }
  },
  computed: {
    isLast () {
      return this.currentIndex === this.data.questions.length - 1
    }
  },
  // watch: {
  //   data: function (current) {
  //     this.currentIndex = 0
  //     this.results = { right: 0, wrong: 0, total: 0 }
  //     this.results.total = current.questions.length
  //     this.current = current
  //   }
  // }
}
</script>
