<template>
  <section ref="main" class="planches-container d-flex flex-column">
    <span class="titre overlay"
      >0{{ currentChapter.number }} - {{ currentChapter.title }}</span
    >

    <section ref="planche_mobile" class="planches" v-if="isMobile">
      <v-stepper v-model="step">
        <v-stepper-items>
          <v-stepper-content step="1">
            <Planche
              @zone-clicked="zone => handleZoneClick('planche_1', zone)"
              :planche="currentChapter.planche_1"
              :tutorial="tutorial"
              :index="1"
            ></Planche>
          </v-stepper-content>
          <v-stepper-content step="2">
            <Planche
              @zone-clicked="zone => handleZoneClick('planche_2', zone)"
              :planche="currentChapter.planche_2"
              :tutorial="tutorial"
              :index="2"
            ></Planche>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </section>
    <div
      class="stepper-actions d-flex justify-space-between align-center"
      v-if="isMobile"
    >
      <v-btn
        :disabled="step === 1"
        @click="
          step = 1
          $emit('currentStep', step)
        "
        icon
        color="white"
        ><v-icon color="black">mdi-chevron-double-left </v-icon></v-btn
      >
      <span :style="'background-color:' + this.currentChapter.color"
        >{{ step }}/2</span
      >
      <v-btn
        :disabled="step === 2"
        @click="
          step = 2
          $emit('currentStep', step)
        "
        icon
        color="white"
        ><v-icon color="black">mdi-chevron-double-right </v-icon></v-btn
      >
    </div>

    <section
      v-else-if="!isMobile && currentChapter.id"
      class="planches d-flex align-center justify-center"
    >
      <Planche
        @zone-clicked="zone => handleZoneClick('planche_1', zone)"
        :planche="currentChapter.planche_1"
        :tutorial="tutorial"
        :index="1"
      ></Planche>
      <Planche
        @zone-clicked="zone => handleZoneClick('planche_2', zone)"
        :planche="currentChapter.planche_2"
        :tutorial="tutorial"
        :index="2"
      ></Planche>
    </section>
    <transition name="fade">
      <ModalGames
        @close="resetZone(true)"
        @previous="changeZone(-1)"
        @next-zone="changeZone(1)"
        v-if="!isLoading && currentComponent === 'game'"
        :data="games"
      />
      <Video
        @close="resetZone(true)"
        @previous="changeZone(-1)"
        @next="changeZone(1)"
        :temp_src="currentVideo"
        v-if="!isLoading && currentComponent === 'video_expert'"
      />
      <VideoComediens
        @close="resetZone(true)"
        @previous="changeZone(-1)"
        @next="changeZone(1)"
        :temp_src="currentVideo"
        v-if="!isLoading && currentComponent === 'video_comedien'"
      />
      <ImageBD
        @close="resetZone(true)"
        @previous="changeZone(-1)"
        @next="changeZone(1)"
        :image="currentImage"
        v-if="!isLoading && currentComponent === 'image'"
      ></ImageBD>
      <!-- <img v-if='!isLoading && currentComponent ==="image"' :src="currentImage" alt=""> -->
      <div class="loading d-flex justify-center align-center" v-if="isLoading">
        <v-progress-circular
          indeterminate
          color="black"
          v-show="isLoading"
        ></v-progress-circular>
      </div>
    </transition>
    <div
      :class="
        currentComponent === 'video_comedien'
          ? 'nav-test video_comedien_nav'
          : 'nav-test'
      "
      v-if="currentZoneOrder || isLoading"
    >
      <v-btn @click="changeZone(-1)" small class="previous-case"
        ><v-icon color="black">mdi-chevron-double-left </v-icon></v-btn
      >
      <span v-if="currentZoneOrder" class="page pa-2"
        >{{
          currentPlanche === 'planche_2'
            ? this.currentChapter['planche_1'].planche_zones.length +
              currentZoneOrder
            : currentZoneOrder
        }}/{{
          this.currentChapter['planche_1'].planche_zones.length +
            this.currentChapter['planche_2'].planche_zones.length
        }}</span
      >
      <v-btn @click="changeZone(1)" small class="next-case"
        ><v-icon color="black">mdi-chevron-double-right </v-icon></v-btn
      >
    </div>
    <v-btn
      v-if="
        !isMobile && chapters.length && currentChapter.id !== chapters[0].id
      "
      icon
      @click="changeChapter(-1)"
      class="previous-case"
      ><v-icon color="#b4b2a3">mdi-chevron-double-left </v-icon></v-btn
    >
    <v-btn
      v-if="
        !isMobile &&
          chapters.length &&
          currentChapter.id !== chapters[chapters.length - 1].id
      "
      icon
      @click="changeChapter(1)"
      class="next-case"
      ><v-icon color="#b4b2a3">mdi-chevron-double-right </v-icon></v-btn
    >
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Planche from '@/components/BD/Planche'
import ModalGames from '@/components/games/Modal_Games.vue'
import Video from '@/components/Video.vue'
import VideoComediens from '@/components/BD/VideoComediens.vue'
import ImageBD from '@/components/BD/Image.vue'
import Hammer from 'hammerjs'

export default {
  data: () => ({
    step: 1,
    video: null,
    plancheData: null,
    currentComponent: null,
    currentVideo: null,
    currentImage: null,
    games: [],
    currentZone: null,
    currentPlanche: null,
    isLoading: false,
    tutorial: false
  }),
  components: {
    Planche,
    ModalGames,
    Video,
    VideoComediens,
    ImageBD
  },
  props: {
    currentStep: {
      type: Number,
      default: 1
    },
    openScore: {
      type: Boolean
    }
  },
  async mounted () {
    // this.plancheData = await this.getPlancheData(this.planche_id)
    this.$emit('loaded', this.plancheData)
    if (this.currentChapter.number === 1 && !this.currentChapter.lastZone) {
      this.tutorial = true
    }
    console.log('NEW CHAP', this)
    if (this.currentStep) this.step = this.currentStep
    window.onkeydown = this.handleArrow
    // if (currentZoneOrder)

    if (this.isMobile) this.enableSwipe()
  },
  computed: {
    ...mapGetters('Chapter', [
      'currentChapter',
      'chapters',
      'currentChapterLogData'
    ]),
    ...mapGetters('Auth', ['user']),
    ...mapGetters(['isMobile', 'appWidth']),
    currentZoneOrder: function () {
      return this.currentZone?.order
    },
    currentZoneIndex: function () {
      return this.currentZone?.order - 1
    }
  },
  methods: {
    ...mapActions('Medias', ['getPlancheData']),
    ...mapActions('Chapter', ['addPlancheZoneVisited']),
    enableSwipe () {
      const self = this
      var mc_planche = new Hammer.Manager(this.$refs.planche_mobile)
      var Swipe = new Hammer.Swipe()
      mc_planche.add(Swipe)

      // subscribe to events
      mc_planche.on('swipeleft', function (e) {
        if (self.step === 1) {
          self.step = 2
        } else {
          if (self.currentChapter.number !== 6) {
            self.changeChapter(1)
          }
        }
      })
      mc_planche.on('swiperight', function (e) {
        if (self.step === 2) {
          self.step = 1
        } else {
          if (self.currentChapter.number !== 1) {
            self.changeChapter(-1)
          }
        }
      })
    },
    async handleZoneClick (planche, zone) {
      console.log(zone)
      if (!zone.planche_zone_visiteds.length) {
        await this.addPlancheZoneVisited({
          user_id: this.user.id,
          planche_zone_id: zone.id
        })
      }

      // Send log data
      if (this.currentChapterLogData) {
        await this.$store.dispatch(
          'Logs/insertLogBySlug',
          this.currentChapterLogData
        )
      }

      this.currentZone = zone
      this.currentPlanche = planche
      if (zone.zone_type === 'game') {
        this.games = zone.planche_zone_media.map(zone_media => zone_media.game)
      } else if (zone.zone_type === 'video_expert') {
        this.currentVideo = zone.planche_zone_media[0].video_expert
      } else if (zone.zone_type === 'video_comedien') {
        this.currentVideo =
          process.env.VUE_APP_AWS_S3_URL +
          '/' +
          zone.planche_zone_media[0].video_comedien.path
      } else if (zone.zone_type === 'image') {
        this.currentImage =
          process.env.VUE_APP_AWS_S3_URL +
          '/' +
          zone.planche_zone_media[0].image.path
      } else if (zone.zone_type === 'score') {
        this.$emit('openScore')
      }
      this.currentComponent = zone.zone_type
    },
    changeZone (direction) {
      this.$emit('removeScore')
      const currentIndex = this.currentZone.order - 1
      let nextIndex = currentIndex + direction
      if (nextIndex < 0) {
        if (this.currentPlanche === 'planche_2') {
          this.currentPlanche = 'planche_1'
          this.step = 1
          this.$emit('currentStep', 1)
          nextIndex =
            this.currentChapter[this.currentPlanche].planche_zones.length - 1
        } else return this.resetZone(true)
      } else if (
        nextIndex >
        this.currentChapter[this.currentPlanche].planche_zones.length - 1
      ) {
        if (this.currentPlanche === 'planche_1') {
          this.currentPlanche = 'planche_2'
          this.step = 2
          this.$emit('currentStep', 2)
          nextIndex = 0
        } else return this.resetZone(true)
      }
      this.resetZone()
      this.isLoading = true
      setTimeout(() => {
        this.handleZoneClick(
          this.currentPlanche,
          this.currentChapter[this.currentPlanche].planche_zones[nextIndex]
        )
        this.isLoading = false
      }, 1000)
    },
    changeChapter (direction) {
      const chapterIndex = this.chapters.findIndex(
        chapter => chapter.id === this.currentChapter.id
      )
      const chapter = this.chapters[chapterIndex + direction]
      this.$router.push({
        name: 'chapitre',
        params: { id: chapter.number, chapter }
      })
    },
    resetZone (refreshChapter) {
      this.currentComponent = null
      this.currentVideo = null
      this.currentImage = null
      this.games = []
      this.currentZone = null
      if (refreshChapter) this.$emit('refresh')
    },
    handleArrow (e) {
      if (this.currentZoneOrder) {
        switch (e.key) {
          case 'ArrowLeft':
            this.changeZone(-1)
            break
          case 'ArrowRight':
            this.changeZone(1)
            break

          default:
            break
        }
      }
    }
  },
  watch: {
    openScore: function (newValue) {
      if (!newValue) this.resetZone(true)
    }
  }
}
</script>

<style lang="scss">
.cases {
  width: 100vw;
  height: 100vh;
  top: 0;
  position: fixed;
}
.zone {
  position: absolute;
}
.loading {
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: rgba(0, 0, 0, 0.6);
  z-index: 15;
  background-color: rgba(255, 255, 255, 0.95);
}
.nav-test {
  button span {
    margin-bottom: 5px;
  }
  position: absolute;
  // background-color: rgba(red, 0.3);
  // height:100%;
  z-index: 9999;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  left: 50%;
  transform: translateX(-50%);
  right: 0;
  width: 102%;
  button {
    padding: 0 !important;
  }
  .switch {
    padding: 10px;
    background: red;
    color: white;
  }
  .page {
    position: absolute;
    @media only screen and (min-width: 1920px) {
      bottom: 11vh;
    }
    bottom: 4vh;
    left: 50%;
    transform: translateX(-50%);
    border: 2px solid black;
    border-radius: 5px;
    padding: 0 5px !important;
  }
}
</style>
