<template>
  <section v-if='current' class="qcm">
    <Question @next='handleNext' @result="addResult" :current='current'/>
  </section>
</template>
<script>
import Question from './QCM_Question'
import { handleScore } from '@/mixins/handleScore.js'

export default {
  props: ['data'],
  components: {
    Question
  },
  mixins: [handleScore],
  data () {
    return {
      currentIndex: 0,
      current: null,
      results: { right: 0, wrong: 0, total: 0 },
    }
  },
  mounted () {
    this.results.total = this.data.questions.length
    this.current = this.data.questions[this.currentIndex]
  },
  methods: {
    handleNext () {
      if (this.currentIndex !== this.data.questions.length - 1) {
        this.currentIndex++
        this.current = this.data.questions[this.currentIndex]
      } else {
        this.$_handleScore()
        this.$emit('next')
      }
    },
    addResult(result) {
      if (result) this.results.right++
      else this.results.wrong++
    },
  },
  // watch: {
  //   data: function (current) {
  //     this.currentIndex = 0
  //     this.results = { right: 0, wrong: 0, total: 0 }
  //     this.results.total = current.questions.length
  //     this.current = this.data.questions[this.currentIndex]
  //   }
  // }
}
</script>
