<template>
    <section class="navbar pa-4">
        <div class="mobile">
          <img @click="$router.push('/menu')" class="retour-menu" src="@/assets/images/icons/menu-retour.svg" style="cursor: pointer">
          <img @click="handleCloseOpenInfos" src="@/assets/images/icons/about.svg" style="cursor: pointer">
          <img @click="handleOpenLib"  v-if="this.$route.name === 'chapitre'" src="@/assets/images/icons/single-file.svg" style="cursor: pointer">
          <img @click="handleOpenLib" v-else src="@/assets/images/icons/library.svg" style="cursor: pointer">
          <img @click="handleOpenScore"  src="@/assets/images/icons/rewards.svg" style="cursor: pointer">
        </div>
        <div class="desktop">
          <img @click="$router.push('/menu')" class="retour-menu" src="@/assets/images/icons/menu-retour.svg" style="cursor: pointer">
          <div class="list">
            <div v-for='chapter in chapters' :key='chapter.id' class="menu-item-desktop" @click="changeChapter(chapter)">
                <span class="index" :style='currentChapter.id === chapter.id && "background-color:"+currentChapter.color+";color:#201E3A;border: 1px solid black"'><span class="inner-index">0{{chapter.number}}</span></span>
                <span class="item-title">{{chapter.title}}</span>

            </div>
          </div>

        </div>
        <div class="mobile-landscape flex-column align-center">
            <img @click="$router.push('/menu')" class="retour-menu" src="@/assets/images/icons/menu-retour.svg" style="cursor: pointer">
          <div class="landscape-nav d-flex flex-column">
            <img @click="handleCloseOpenInfos" class="mb-4" src="@/assets/images/icons/about.svg" style="cursor: pointer">
            <img @click="handleOpenLib" v-if="this.$route.name === 'chapitre'" class="mb-4" src="@/assets/images/icons/single-file.svg" style="cursor: pointer">
            <img @click="handleOpenLib" v-else class="mb-4" src="@/assets/images/icons/library.svg" style="cursor: pointer">
            <img @click="handleOpenScore" class="mb-4"  src="@/assets/images/icons/rewards.svg" style="cursor: pointer">
          </div>

          <!-- <div class="mt-auto">
            <span class="index" :style='"background-color:"+currentChapter.color+";color:#201E3A;"'><span class="inner-index">0{{currentChapter.number}}</span></span>
            <h2 class="nav-title mb-10" :style='"color:"+currentChapter.color'>{{currentChapter.title}}</h2>

          </div> -->
          <transition name="fade">
            <Menu :isModal='true' @closeAndOpenInfos='handleCloseOpenInfos' @closeAndOpenLib="handleOpenLib" @closeAndOpenScore="handleOpenScore" :chapters='chapters' @close='openMenu=false' v-if='openMenu'/>

        </transition>
        </div>
    </section>
</template>
<script>
import Menu from '@/views/Menu.vue'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      openMenu: false
    }
  },
  components: {
    Menu
  },
  computed: {
    ...mapGetters('Chapter', ['chapters', 'currentChapter'])
  },
  async created () {
    await this.$store.dispatch('Chapter/getChapterList')
  },
  methods: {
    handleOpenLib () {
      this.openMenu = false
      setTimeout(() => {
        this.$emit('openLibrary')
      }, 600)
    },
    handleOpenScore () {
      this.openMenu = false
      setTimeout(() => {
        this.$emit('openScore')
      }, 600)
    },
    handleCloseOpenInfos () {
      this.openMenu = false
      setTimeout(() => {
        this.$emit('openInfos')
      }, 600)
    },
    changeChapter (chapter) {
      if (this.isModal) this.$emit('close')
      if (this.$route.params.id == chapter.number) return
      this.$router.push({ name: 'chapitre', params: { id: chapter.number, chapter } })
    }
  }
}
</script>
