<template>
    <section class="quizz-question">
       <span class="question">{{data.text}}</span>
        <div class="choices mb-5">

       <v-btn :class="hasAnswered && data.answers[0].is_correct === false ? 'faded' :null" @click='!hasAnswered ? handleAnswer(true) : null' :outlined='isTrueOutlined' style="border-width:3px" :color="!hasAnswered ? 'primary': data.answers[0].is_correct === true ? 'success' : 'error' ">Vrai</v-btn> <br>
       <v-btn :class="hasAnswered && data.answers[0].is_correct === true ? 'faded' :null" @click="!hasAnswered ? handleAnswer(false) : null" :color="!hasAnswered ? 'primary': data.answers[0].is_correct === false ? 'success' : 'error' "  :outlined='isFalseOutlined'  style="border-width:3px">Faux</v-btn>
        </div>
        <div v-if='hasAnswered' class="results">
        <transition name="fade">
            <MotExpert v-if='hasAnswered && data.correction' :type="answer === data.answers[0].is_correct ? 'is-success' : 'is-danger'" :message='data.correction'/>
        </transition>
        <v-btn class="mt-5" v-if='hasAnswered && !isLast' @click="$emit('nextQuestion')" color="primary">suivant</v-btn>
        <v-btn class="mt-5" v-if='hasAnswered && isLast' @click="$emit('next')" color="primary">continuer</v-btn>
        </div>
    </section>
</template>
<script>
import MotExpert from '@/components/games/MotExpert.vue'
export default {
  props: ['data', 'isLast'],
  components: {
    MotExpert
  },
  data () {
    return {
      hasAnswered: false,
      answer: null,
    }
  },
  computed: {
    isRightAnswer () {
      return this.answer === this.data.answers[0].is_correct
    },
    isTrueOutlined() {
      let value = true;
      if(!this.hasAnswered) {
        value = true
      }
      else {
        if(this.answer === true) {
          value = false
        }
        else {
          value = true
        }
      }
      return value
    },
    isFalseOutlined() {
     let value = true;
      if(!this.hasAnswered) {
        value = true
      }
      else {
        if(this.answer === false) {
          value = false
        }
        else {
          value = true
        }
      }
      return value
    }
  },
  watch: {
    data: function () {
      this.answer = null
      this.hasAnswered = null
    },
  },
  mounted () {
  },
  methods: {
    handleAnswer(answer) {
      this.hasAnswered = true
      this.answer = answer
      this.$emit('update-score', this.isRightAnswer)
    },

  }
}
</script>
