<template>
  <v-container class="video-comediens" ref="video-comedien"  >
    <div :class="loading ? 'comediens-container hidden' : 'comediens-container'" :style="'width: '+ videoWidth + 'px'" >
<!-- :style="'width: ' + videoWidth +'px'" -->
    <v-btn small outlined color="white" class="mb-2 close-btn"  @click="$emit('close')" alt="close" ><v-icon color="black">mdi-close</v-icon></v-btn>
      <div class="player" >
              <video
              @click='handlePlayPause'
        @ended="displayPlay = true"
        v-show='!loading'
        ref="video"
        :src="video_src"
        @canplaythrough="handleLoad"
        @timeupdate='handleProgress'
        autoplay playsinline
        @play='handleDisplayPause'

      ></video>
      <transition name="fade">
        <img
          v-if="displayPause"
          @click="handlePlayPause"
          src="@/assets/images/icons/pause.svg"
          alt="pause"
          class="pause"
        />
      </transition>
      <transition name="fade">
        <img
          v-if="displayPlay"
          @click="handlePlayPause"
          src="@/assets/images/icons/play.svg"
          alt="Play"
          class="pause"
        />
      </transition>
      </div>
      <v-slider
        :max="duration"
        v-if='video'
        :value="currentTime"
        min="0"
        class="length"
        height="16px"
        :step="0.001"
        @change="handleChangeTime"
        track-color="#b4b2a3"
      ></v-slider>
    </div>
      <v-progress-circular
      indeterminate
      color="black"
      v-show='loading'
      class='loading'
    ></v-progress-circular>
  </v-container>
</template>

<script>
// import enableInlineVideo from 'iphone-inline-video'
import Hammer from "hammerjs"

export default {
  props: ['data', 'temp_src'],
  data () {
    return {
      ctx: null,
      width: 0,
      height: 0,
      video: null,
      duration: 0,
      currentTime: 0,
      displayPause: false,
      displayPlay: false,
      loading: true,
      video_src: null,
      videoWidth: null
    }
  },
  async mounted () {
    // this.$store.dispatch('Medias/getVideoByContentId', this.data.node_content).then(response => {
    this.video_src = this.temp_src
    this.video = document.querySelector('video')
    // this.video.crossOrigin = 'Anonymous'
    // enableInlineVideo(this.video)
    // this.handleDisplayPause()
    window.addEventListener('keypress', this.handleSpace)
    this.enableSwipe()
    // })
  },
   watch:{
      video:{
        deep:true,
        handler:function(newVal) {
             if(newVal.paused) {
          this.displayPlay = true
        }
        else {
          this.displayPlay = false
        }
        }
      }
    },
  methods: {
    enableSwipe() {
      let self = this;
      var Swipe = new Hammer.Swipe()
      var mc_main = new Hammer.Manager(this.$refs['video-comedien'])
      mc_main.add(Swipe)
        mc_main.on('swipeleft', function(e) {
         self.$emit('next')
      })
      mc_main.on('swiperight', function(e) {
        self.$emit('previous')
      })
    },
    handleDisplayPause () {
      this.displayPlay = false
      this.displayPause = true
      setTimeout(() => {
        this.displayPause = false
      }, 1500)
    },
    handleDisplayPlay () {
      this.displayPause = false
      this.displayPlay = true
      setTimeout(() => {
        this.displayPlay = false
      }, 1500)
    },
    handleChangeTime (e) {
      this.video.currentTime = e
      this.video.play()
    },
    handlePlayPause () {
      if (this.video.paused) {
        this.video.play()
        this.handleDisplayPause()
      } else {
        this.video.pause()
        this.handleDisplayPlay()
      }
    },
    handleProgress (e) {
      this.currentTime = e.target.currentTime
    },
    handleLoad (e) {
      if (this.loading === true) {
        // this.loading = false
        this.video.play()
        this.duration = this.video.duration
        this.loading = false
        this.videoWidth = this.video.videoWidth / 2
        // this.width = this.video.clientWidth * 1.5
        // this.height = this.video.clientHeight * 1.5
        // this.$refs.canvas.width = this.width
        // this.$refs.canvas.height = this.height
        // this.ctx = this.$refs.canvas.getContext('2d')
        // this.handleVideo()
      }
    },
    handleSpace(e) {
      if(e.key===' ') this.handlePlayPause()
    }
    // handleVideo (e) {
    //   this.currentTime = this.video.currentTime
    //   this.ctx.drawImage(this.video, 0, 0, this.width, this.height)
    //   const frame = this.ctx.getImageData(0, 0, this.width, this.height)
    //   const l = frame.data.length
    //   for (let i = 0; i < l; i++) {
    //     const r = frame.data[i]
    //     const g = frame.data[i + 1]
    //     const b = frame.data[i + 2]
    //     if (g > 90 && g < 165 && r > 40 && r < 115 && b > 40 && b < 95) {
    //       frame.data[i + 3] = 0
    //     }
    //   }
    //   this.ctx.putImageData(frame, 0, 0)
    //   requestAnimationFrame(this.handleVideo)
    // }
  },
  beforeDestroy() {
    window.removeEventListener('keypress', this.handleSpace)
  }
}
</script>
