<template>
    <section class="qcm-question pt-4">
    <span class="question-title">{{current.text}}</span>
    <ol>
      <li
        v-for="(choice, i) in answers"
        @click="() => handleClick(i)"
        :id="'choice-' + i"
        :class="choice.status"
        :key="choice.id"
      >
        <span class="index"
          ><span class="value">{{ String.fromCharCode(97 + i) }}</span></span
        >
        <span class="is-title">{{ choice.text }}</span>
      </li>
    </ol>
    <transition name="fade">
      <MotExpert v-if="hasCheck && current.correction" :message="current.correction" />
    </transition>
    <v-btn
      :disabled="choicesLength == 0"
      :class="hasCheck ? 'mt-4' : null"
      @click="handleCheck"
      color="primary"
      >{{ hasCheck ? 'Continuer' : 'valider' }}</v-btn
    >
    </section>
</template>
<script>
import MotExpert from '@/components/games/MotExpert.vue'

export default {
  components: {
    MotExpert
  },
  props: ['current'],
  watch: {
    current: function () {
      this.hasCheck = false
      this.choice = new Set()
      this.choicesLength = 0
      this.answers = JSON.parse(JSON.stringify(this.current.answers))
    }
  },
  data () {
    return {
      choice: new Set(),
      choicesLength: 0,
      hasCheck: false,
      answers: []
    }
  },
  created () {
    this.answers = JSON.parse(JSON.stringify(this.current.answers));
  },
  computed: {
    hasMultipleValidAnswers() {
      const correctAnswers = this.current.answers.filter((answer) => {
        return answer.is_correct
      })

      return (correctAnswers.length > 1)
    },
    isCorrect () {
      if (this.hasMultipleValidAnswers) {
        // Check if one correct answer is not checked
        let isCorrect = true
        for (var i = 0; i < this.current.answers.length; i++) {
          const wasChosen = this.choice.has(i)
          if (!this.current.answers[i].is_correct) isCorrect = false
          else if (this.current.answers[i].is_correct && !wasChosen) {
            isCorrect = false
          }
        }

        return isCorrect
      } else {
        // Check if the selected answer is correct
        let rightResponseIndex = this.current.answers.findIndex(answer => answer.is_correct)
        if (rightResponseIndex && this.choice.has(rightResponseIndex)) return true
      }
      return false

    }
  },
  methods: {
    handleClick (index) {
      const item = document.getElementById('choice-' + index)
      if (item.classList.contains('is-active')) {
        item.classList.remove('is-active')
        this.choice.delete(index)
      } else {
        if (!this.hasMultipleValidAnswers) this.removeAllActive()
        item.classList.add('is-active')
        this.choice.add(index)
      }
      this.choicesLength = this.choice.size
    },
    handleCheck () {
      if (!this.hasCheck) {
     this.hasCheck = true
      if(this.isCorrect) this.answers.forEach(item =>{
        if(item.is_correct){ item.status = 'is-success'}
      })
        else {
          this.answers.forEach((item, i) => {
            const wasChosen = this.choice.has(i)
            if (item.is_correct) {
              item.status = 'is-success'
            } else {
              if (wasChosen) {
                item.status = 'is-danger'
              }
            }
          })

        }
        this.$emit('result', true)
      } else {
        this.$emit('next')
      }
    },
    removeAllActive() {
      let activeCheckboxes = document.getElementsByClassName('is-active')

      if(activeCheckboxes.length) {
        activeCheckboxes.forEach(checkbox => {
          checkbox.classList.remove('is-active')
        })
        this.choice = new Set()
      }
    },
  
  }
}
</script>
