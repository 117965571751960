<template>
  <section class="BD">
    <Navbar
      @openScore="openScore = true"
      @openLibrary="openLibrary = true"
      @openInfos='openInfos = true'
    />
    <section class="chapitre" :key="refresh">
      <chapter @removeScore="openScore = false" @openScore="openScore = true" :openScore="openScore" :key="currentChapter.id" @zone='handleClickBD' @refresh="refreshChap" @currentStep="step => currentStep = step" :currentStep="currentStep"></chapter>
    </section>
    <RightNavbar
      @openScore="openScore = true"
      @openLibrary="openLibrary = true"
      @openInfos='openInfos = true' />
    <transition name="fade">
      <Library @close="openLibrary = false" v-if="openLibrary" />
      <Score @close="openScore = false" v-if="openScore" />
      <!-- <ModalGames
        @close="currentComponent = null"
        v-if="currentComponent === 'game'"
        :data="games"
      /> -->
      <UserInfos  @close="openInfos = false" v-if='openInfos'/>
      <Video @close='currentComponent=null' :temp_src='currentVideo' v-if='currentComponent ==="video_expert"'/>
      <Success @closeOpenScore='handleCloseOpenScore'  @close='successOpened=false' v-if='successOpened'/>
      <VideoComediens @close='currentComponent=null' temp_src="pokokok" :data='currentVideo' v-if='currentComponent === "video_comedien"'/>
    </transition>
  </section>
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import RightNavbar from '@/components/RightNavbar.vue'
import Library from '@/components/Library.vue'
import Score from '@/components/Score.vue'
import ModalGames from '@/components/games/Modal_Games.vue'
import Video from '@/components/Video.vue'
import Success from '@/components/Success.vue'
import UserInfos from '@/components/UserInfos.vue'
import VideoComediens from '@/components/BD/VideoComediens.vue'
import chapter from '@/components/BD/Chapitre.vue'
import FooterNavigation from '@/components/Footer_Navigation.vue'

import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    Navbar,
    Library,
    Score,
    ModalGames,
    Video,
    Success,
    UserInfos,
    VideoComediens,
    chapter,
    FooterNavigation,
    RightNavbar
  },
  data () {
    return {
      openLibrary: false,
      openScore: false,
      gameOpened: null,
      videoOpened: false,
      successOpened: false,
      openInfos: false,
      videoComedien: false,
      currentScenario: null,
      games: [],
      user: {
        firstName: 'Alban',
        lastName: 'Bleicher',
        residence: 'Ma super résidence',
        bailleur: 'Mon super bailleur',
        ville: 'Paris',
        region: 'Ile-de-France'
      },
      video: null,
      currentComponent: null,
      currentVideo: null,
      data: null,
      refresh: 0,
      currentStep: null
    }
  },
  async created () {
    await this.$store.dispatch('Chapter/getChapterList')
    const currentChap = this.chapters.find(chapter => chapter.number == this.$route.params.id)
    this.$vuetify.theme.themes.light.secondary = currentChap.color
    if (currentChap) await this.getChapterById(currentChap.id)
  },
  async mounted () {
    switch (this.$router.history.current.params.modal) {
      case 'infos':
        this.openInfos = true
        break
      case 'score':
        this.openScore = true
        break
      case 'library':
        this.openLibrary = true
        break
      default:
        break
    }
    setTimeout(() => {
      this.currentComponent = 'game'
    }, 2000)
  },
  computed: {
    ...mapGetters('Chapter', ['chapters', 'currentChapter'])
  },
  methods: {
    ...mapActions('Chapter', ['getChapterById']),
    handleClickBD (zone) {
    //   if (zone.zone_type === 'game') {
    //     this.games = zone.planche_zone_media.map(zone_media => zone_media.game)
    //   } else if (zone.zone_type === 'video_expert') {
    //     this.currentVideo = zone.planche_zone_media[0].video_expert.url
    //   } else if (zone.zone_type === 'video_comedien') {
    //     this.currentVideo = zone.planche_zone_media[0].video_comedien.url
    //   } else if (zone.zone_type === 'image') {
    //     this.currentImage = zone.planche_zone_media[0].video_comedien.url
    //   }
    //   this.currentComponent = zone.zone_type
    },
    handleCloseOpenScore () {
      setTimeout(() => {
        this.openScore = true
      }, 1000)
      this.successOpened = false
    },
    async refreshChap () {
      await this.getChapterById(this.currentChapter.id)
      this.refresh++
    }
  },
  watch: {
    '$route.params.id': async function (id) {
      const currentChap = this.chapters.find(chapter => chapter.number == id)
      this.$vuetify.theme.themes.light.secondary = currentChap.color
      if (currentChap) await this.getChapterById(currentChap.id)
    }
  }
}
</script>

<style lang="scss">
.chapitre {
  width: 100%;
  // height : calc(100% - 82px);
    // background: red;
  @media screen and (min-width: 1200px) {
    width: calc(100% - 120px);
    height: 100vh;
    display: flex;
    // padding: 100px 0;

    // box-sizing: border-box;
    flex-direction: column;
  }
  @media screen and (orientation:landscape) and (max-width: 1200px){
    width: calc(100% - 120px);
    height: 100vh;
  }
}
</style>
