<template>
  <section class="right-navbar align-center justify-center">
    <div class="nav-actions d-flex flex-column">

      <div class="nav-item">
        <img class="mb-2" @click="handleCloseOpenInfos" src="@/assets/images/icons/about.svg" style="cursor: pointer">
        <div class="nav-item-title"><span>Mon compte</span></div>
      </div>
      <div v-if="this.$route.name === 'chapitre'" class="nav-item">
      <img class="mb-2"  @click="handleOpenLib" src="@/assets/images/icons/single-file.svg" style="cursor: pointer">
        <div class="nav-item-title"><span>Documentation de l'épisode</span></div>

      </div>
      <div v-else class="nav-item">
      <img class="mb-2" @click="handleOpenLib" src="@/assets/images/icons/library.svg" style="cursor: pointer">
        <div class="nav-item-title"><span>Bibliothèque</span></div>
      </div>
      <div class="nav-item">
      <img class="mb-2" @click="handleOpenScore"  src="@/assets/images/icons/rewards.svg" style="cursor: pointer">
        <div class="nav-item-title"><span>Mon score</span></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    handleOpenLib () {
      this.openMenu = false
      setTimeout(() => {
        this.$emit('openLibrary')
      }, 600)
    },
    handleOpenScore () {
      this.openMenu = false
      setTimeout(() => {
        this.$emit('openScore')
      }, 600)
    },
    handleCloseOpenInfos () {
      this.openMenu = false
      setTimeout(() => {
        this.$emit('openInfos')
      }, 600)
    },
  },
}
</script>

<style lang="scss">

</style>
