<template>
    <v-container class="card-detail">
        <div ref="card" :class="hasAnswered ? isCorrect ?'card-opened is-success' : 'card-opened is-error' : 'card-opened' ">
            {{data.answer}}
        </div>
         <div class="choices" v-if='!hasAnswered'>
             <v-btn @click="handleNo" color='primary' outlined style='border:4px solid'>NON</v-btn>
         <v-btn @click='handleYes' depressed color='primary'>OUI</v-btn>
         </div>
         <div v-else>
             <p class="message">{{isCorrect ? "Bravo, c'est une bonne réponse !" : "Dommage, c'est une mauvaise réponse !"}}</p>
         </div>
    </v-container>
</template>
<script>
export default {
  props: ['data', 'current'],
  data () {
    return {
      hasAnswered: false,
      isCorrect: false,
      message: ''
    }
  },
  methods: {
    handleYes () {
      this.hasAnswered = true
      if (this.data.question === this.current.question) {
        if (this.data.answer === this.current.answer) {
          this.isCorrect = true
          this.$emit('reveal', this.data.question)
        }
      } else {
        this.isCorrect = false
      }
      setTimeout(() => {
        this.$emit('close')
      }, 1500)
    },
    handleNo () {
      this.hasAnswered = true
      this.$emit('close')
    }
  }
}
</script>
