<template>
  <section class="planche">
    <section class="planche-img-container">
      <img class="planche-img" :src="planche.url" alt="" />
      <!-- <div class="zone"  @click="$emit('zone-clicked', zone)" :style="'background:rgba(0,0,0,0.2);border:2px solid red;position:absolute;top:'+zone.position.top+'%;left:'+zone.position.left+'%;right:'+zone.position.right+'%;bottom:'+zone.position.bottom+'%'" :data-type="zone.zone_type"></div> -->
      <PlancheZone
        v-for="(zone, i) in planche.planche_zones"
        :key="zone.id"
        :zone="zone"
        @zone-clicked="zone => $emit('zone-clicked', zone)"
        :disabled="
          index === 1
            ? tutorial && i > 0
              ? true
              : false
            : tutorial
            ? true
            : false
        "
        :tutorial="tutorial"
      ></PlancheZone>
    </section>
  </section>
</template>

<script>
import PlancheZone from '@/components/BD/PlancheZone'
export default {
  props: {
    planche: {
      type: Object,
      default: () => ({})
    },
    tutorial: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    }
  },
  components: {
    PlancheZone
  }
}
</script>
