<template>
  <div
    :class="tutorial ? (disabled ? 'zone disabled' : 'zone tutorial') : 'zone'"
    @click="zoneCliked"
    :key="isCliked"
    :style="
      `position:absolute;top: ${zone.position.top}%;left:${zone.position.left}%;right:${zone.position.right}%;bottom:${zone.position.bottom}%;opacity:0.6`
    "
    :data-type="zone.zone_type"
  >
    <span
      v-if="
        currentChapter.lastZone &&
          currentChapter.lastZone.planche_zone.id === zone.id
      "
      class="zone-reminder"
    ></span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    zone: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tutorial: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isCliked: false
  }),
  computed: {
    ...mapGetters('Chapter', ['currentChapter'])
  },
  methods: {
    zoneCliked () {
      this.isCliked = true
      this.$emit('zone-clicked', this.zone)
    }
  }
}
</script>

<style lang="scss">
.zone-reminder {
  width: 20px;
  height: 20px;
  background: rgb(63, 233, 30);
  border-radius: 100%;
  position: absolute;
  bottom: 5px;
  right: 5px;
}
</style>
