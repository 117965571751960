<template>
    <div class="reorder">
        <h3>{{data.instruction}}</h3>
       <draggable class="reorder-items" ref="items" v-model="items">
    <transition-group type="transition" name="flip-list">
        <div class="item" v-for="(element, i) in items" :key="element.id">
            <div class="index">
               {{10 > (i+1) ? "0" : null}}{{i+1}}
            </div>
           <div :class="element.isError && !isCorrect ? 'content is-error' : 'content'">
                <span>{{element.text}}</span>
            <img src="@/assets/images/icons/drag-purple.svg" alt="drag">
           </div>
        </div>
    </transition-group>
</draggable>
<transition>
             <v-btn class="mb-4" v-if='hasCheck && !isCorrect' @click='seeCorrection'  color="success">Voir la correction</v-btn>

</transition>
 <transition name="fade">
            <MotExpert v-if='hasCheck && data.correction' :message='data.correction'/>
            <p class="bravo" v-if='hasCheck && items.length === results.right'>Bravo ! </p>
        </transition>
    <v-btn @click='handleNext' :class='isCorrect ? "mt-5" : null' color="primary">{{hasCheck ? 'Continuer' : 'Valider'}}</v-btn>
    </div>
</template>
<script>
import MotExpert from '@/components/games/MotExpert.vue'
import draggable from 'vuedraggable'
import { handleScore } from '@/mixins/handleScore.js'

export default {
  components: {
    draggable,
    MotExpert
  },
  props: ['data'],
  mixins: [handleScore],
  data () {
    return {
      items: null,
      isCorrect: false,
      results: { right: 0, wrong: 0 },
      hasCheck: false
    }
  },
  mounted () {
    this.items = JSON.parse(JSON.stringify(this.lodash.shuffle(this.data.questions)))
    this.results.total = this.items.length
  },
  methods: {
    handleNext () {
      if (this.hasCheck) {
        this.$_handleScore()
        this.$emit('next')
      } else {
        const items = this.items

        this.$delete(items, 'isError')
        if (this.lodash.isEqual(items, this.data.questions)) {
          this.$set(this.items, 'isError', false)
          this.isCorrect = true
          this.results.right = this.items.length
        } else {
          this.items.forEach((element, i) => {
            if (this.data.questions[i].text !== element.text) {
              this.results.wrong++
              this.$set(element, 'isError', true)
            } else {
              this.results.right++
              this.$set(element, 'isError', false)
            }
          })
        }
      }
      this.hasCheck = true
    },
    seeCorrection () {
      this.items = this.data.questions
      document.querySelectorAll('.is-error').forEach(item => {
        item.classList.remove('is-error')
        item.classList.add('is-success')
      })
    }
  }
}
</script>
