<template>
   <div ref="main" class="modal-wrap ">
      <div class="video-modal ma-auto">
        <v-progress-circular
        indeterminate
        color="black"
        v-show='loading'
        class='loading'
        ></v-progress-circular>
        <v-btn v-show="!loading" small outlined color="white" class="mb-2 video-close"  @click="$emit('close')" alt="close"><v-icon color="black">mdi-close</v-icon></v-btn>
        <div class="video-content">
          <transition name="fade">
        <img
          v-if="displayPause && !loading"
          src="@/assets/images/icons/pause.svg"
          alt="pause"
          class="pause"
        />
      </transition>
      <transition name="fade">
        <img
          v-if="displayPlay && !loading"
          src="@/assets/images/icons/play.svg"
          alt="Play"
          class="pause"
        />
      </transition>
          <div v-show="!loading" class="video-container">
            <video class="video" @timeupdate="handleProgress" ref='video' @click='handleClick' @canplaythrough="loaded" :src='video_src' autoplay playsinline
            @play="handleDisplayPlay"></video>
          </div>
          <v-slider
            v-show="!loading"
            :max="duration"
            v-if='video'
            :value="currentTime"
            min="0"
            class="length"
            :step="0.001"
            @change="handleChangeTime"
            track-color="#E5E5E5"
          ></v-slider>
        </div>
    </div>
   </div>
</template>
<script>
import enableInlineVideo from 'iphone-inline-video'
import { mapGetters } from 'vuex'
import Hammer from "hammerjs"
export default {
  props: ['data', 'temp_src'],
  data () {
    return {
      loading: true,
      video_src: null,
      currentTime:0,
      video:null,
      duration:0,
      isChanging:false,
      displayPause:false,
      displayPlay:false
    }
  },
  computed: {
    ...mapGetters('Chapter', ["currentChapter"])
  },
  mounted () {
    this.video_src = process.env.VUE_APP_AWS_S3_URL +'/'+ this.temp_src.path
    this.video= document.querySelector('video')
    window.addEventListener('keypress', this.handleSpace)
    this.enableSwipe()
  },
     watch:{
      video:{
        deep:true,
        handler:function(newVal) {
                  if(newVal.paused) {
          this.displayPlay = true
        }
        else {
          this.displayPlay = false
        }
        }
      }
    },
  methods: {
    enableSwipe() {
      let self = this;
      var Swipe = new Hammer.Swipe()
      var mc_main = new Hammer.Manager(this.$refs['main'])
      mc_main.add(Swipe)
        mc_main.on('swipeleft', function(e) {
         self.$emit('next')
      })
      mc_main.on('swiperight', function(e) {
        self.$emit('previous')
      })
    },
    handleDisplayPause () {
      this.displayPlay = false
      this.displayPause = true
      setTimeout(() => {
        this.displayPause = false
      }, 1500)
    },
      handleDisplayPlay () {
      this.displayPause = false
      this.displayPlay = true
      setTimeout(() => {
        this.displayPlay = false
      }, 1500)
    },
    loaded(){
       if(this.loading) {
          this.video.play()
        this.duration = this.video.duration
        this.loading = false
       }
    },
    handleChangeTime (e) {
      this.isChanging = true
      this.video.currentTime = e

      this.video.pause()
      this.currentTime = e

      setTimeout(() => {
      this.video.play()
      this.isChanging = false
      },300)

    },
    handleProgress (e) {
    if(!this.isChanging) {
      this.currentTime = e.target.currentTime
    }

    },
    handleClick (e) {
      if (this.$refs.video.paused) {
        this.$refs.video.play()
        this.handleDisplayPlay()

      } else {
        this.handleDisplayPause()

        this.$refs.video.pause()
      }
    },
    handleSpace (e) {
      if (e.key === " ") {
        this.handleClick()
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('keypress', this.handleSpace)
  }
}
</script>
