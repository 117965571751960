export const handleScore = {
  methods: {
    $_handleScore () {
      const data = {
        value: this.results.right / this.results.total,
        results: {
          right: this.results.right,
          wrong: this.results.wrong,
          total: this.results.total
        }
      }
      this.$emit('addGameLog', { data, game_id: this.data.id })
    }
  }
}
