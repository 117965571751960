<template>
    <section class="memory" >
       <span v-if='init' class="consigne mb-8">Observez ces réponses</span>
       <span v-else  class="consigne mb-8">{{wait_consigne ? wait_consigne : current.text}}</span>
        <div  class="memory-cards">
            <div :class="countdown !== 0 && init ? 'flip-card reveal' : 'flip-card'" v-for='(card, index) in items' @click.stop='e => handleReveal({e, answer: card.answers[0], index})' :key='card.id'>

                <div class="flip-card-inner">
                    <div class="flip-card-front">
                  ?
                </div>
                <div class="flip-card-back">
                  <div v-if='card.reveal' class="question">{{card.text}}</div>
                  <div>{{card.answers[0].text}}</div>
                </div>
                </div>
            </div>
        </div>
        <v-btn v-if='ended' class="validate" @click='next' color='primary'>Valider</v-btn>
                    <!-- <Card @reveal='handleReveal' @close='handleClose' v-if='current' :current='currentQuestion' :data='current'/> -->
    </section>
</template>
<script>
import Card from './Memory_Card'
import MotExpert from '@/components/games/MotExpert.vue'
import { handleScore } from '@/mixins/handleScore.js'

export default {
  props: ['data'],
  components: {
    Card,
    MotExpert
  },
  mixins: [handleScore],
  data () {
    return {
      ended: false,
      items: [],
      currentIndex: 0,
      current: null,
      gameOver: false,
      countdown: 5,
      items_waiting: [],
      init: true,
      wait_consigne: 'C’est parti ! Trouvez les bonnes cartes correspondant aux questions suivantes',
      tries: 0,
      results: { right: 0, wrong: 0, total: 0 },
      allDone:false,
      correct_sound:null,
      wrong_sound:null
    }
  },
  mounted () {
    this.initGame(this.data)
    let correct = require('@/assets/sounds/correct.wav')
    let wrong  = require('@/assets/sounds/wrong.wav')
    this.correct_sound = new Audio(correct)
    this.correct_sound.volume = 0.1
    this.wrong_sound = new Audio(wrong)
    this.wrong_sound.volume = 0.1
  },
  methods: {
    handleReveal ({e, answer, index}) {
      if(this.wait_consigne) return
      this.tries++
      const card = e.target.parentNode.parentNode
      card.classList.add('reveal')
      if (answer.text !== this.current.answers[0].text) {
        card.querySelector('.flip-card-back').classList.add('wrong')
          this.wrong_sound.play()

        setTimeout(() => {
          card.classList.remove('reveal')

          card.querySelector('.flip-card-back').classList.remove('wrong')
        }, 2000)
        if (this.tries === 1) this.results.wrong++
      } else {
        card.querySelector('.flip-card-back').classList.add('right')
        this.items[index].reveal = true
        this.items_waiting = this.items.filter(item => !item.reveal)
        this.correct_sound.play()
        if (this.tries === 1) this.results.right++
        this.handleCountdown()
      }
    },
    handleCountdown () {
      this.countdown = 0
      if (this.items_waiting.length >= 1) this.wait_consigne = "Prochaine question..."
      const interval = setInterval(() => {
        if (this.countdown !== 0) {
          this.countdown--
        } else {
          this.wait_consigne = ""
          this.tries = 0
          clearInterval(interval)
        }
      }, 4000)
      if (this.items_waiting.length) {
        this.current = this.items_waiting[this.lodash.random(this.items_waiting.length - 1)]
      } else {
        this.ended = true
      }
    },
    next() {
      this.$_handleScore()
      this.$emit('next')
    },
    initGame (current) {
      this.results = { right: 0, wrong: 0, total: 0 }
      this.results.total = current.questions.length
      this.current = current
      this.ended = false
      this.items = []
      this.items_waiting = []
      this.currentIndex = 0
      this.gameOver = false
      this.countdown = 5
      this.init = true
      this.countdownMessage = ''
      this.wait_consigne = 'C’est parti ! Trouvez les bonnes cartes correspondant aux questions suivantes'
      this.tries = 0
      this.items = JSON.parse(JSON.stringify(this.lodash.shuffle(current.questions)))
      this.results.total = current.questions.length
      this.items.forEach(item => {
        item.reveal = false
      })
      this.items_waiting = this.items
      this.current = JSON.parse(JSON.stringify(this.items[this.lodash.random(this.items.length - 1)]))
      setTimeout(() => {
        this.init = false
        this.wait_consigne = ""
      }, 12000);
    }
  },
  // watch: {
  //   data: function (current) {
  //     this.initGame(current)
  //   }
  // }
}
</script>
