<template>
    <div class="gap">
      <span class="consigne">{{data.instruction}}</span>
    <div class="fillers d-flex flex-column">
      <span v-for='(item, i) in choices' :key="item.isHidden +'' + i"  class="answer pa-2" @click="answerSelected(item, i)" :style="selectedAnswer && selectedAnswer.id === item.id ? 'background:#0f5b91 ;border:2px solid #0f5b91;color: white' : item.isHidden ? 'opacity: 0.5' :  'background:white ;border:2px solid #0f5b91;color: #0f5b91' ">{{item.answers[0].text}}</span>
    </div>
    <hr style='background:lightgrey;border:none;height:3px'>
    <div class="content">
        <div v-for="(item, i) in propositions" :key="item.id">
          {{item.text}}
          <p :ref="`answer-${i}`" :style="answers[i] ? 'border:2px solid #0f5b91;color: #0f5b91': null" class="answer-zone pa-2" @click="addAnswer(i)">{{answers[i] && answers[i].answer}}</p>
        </div>
    </div>
    <v-btn class='mt-5 mb-4' :disabled='!canValidate' v-if="!hasCheck" @click='handleCheck' color='primary'>Valider</v-btn>
    <v-btn class='mt-5 mb-4' v-else @click="$emit('next')" color='primary'>Continuer</v-btn>
    <v-btn class='mt-5 mb-4' v-if='errors && hasCheck' @click='seeCorrection' color='success'>Voir la correction</v-btn>
    <MotExpert v-if='hasCheck && data.correction' :message='data.correction'/>
    </div>
</template>
<script>
import MotExpert from '@/components/games/MotExpert.vue'
import { handleScore } from '@/mixins/handleScore.js'
export default {
  props: ['data'],
  mixins: [handleScore],
  components: {
    MotExpert
  },
  data () {
    return {
      choices: [],
      propositions: [],
      answers: [],
      errors: 0,
      hasCheck: false,
      results: { right: 0, wrong: 0, total: 0 },
      currentDrag: null,
      refresh: 0,
      selectedAnswer: null,
      answerIndex: null,
      canValidate: false
    }
  },
  mounted () {
    this.propositions = JSON.parse(JSON.stringify(this.lodash.shuffle(this.data.questions)))
    this.choices = JSON.parse(JSON.stringify(this.lodash.shuffle(this.data.questions)))
    this.answers = new Array(this.propositions.length).fill(null)

    this.results.total = this.data.questions.length
  },
  methods: {
    seeCorrection () {
      this.errors = 0
      this.answers.forEach(item => {
        item.element.innerText = this.propositions[item.questionIndex].answers[0].text
        item.element.style.background = 'green'
      })
    },
    handleCheck () {
      if (!this.hasCheck) {
        this.hasCheck = true
        this.answers.forEach(answer => {
          if (this.propositions[answer.questionIndex].answers[0].text === answer.answer) {
            this.results.right++
            answer.element.style.background = 'green'
            answer.element.style.color = 'white'
          } else {
            answer.element.style.background = 'red'
            answer.element.style.color = 'white'
            this.results.wrong++
            this.errors += 1
          }
        })
        this.$_handleScore()
      }
    },
    answerSelected (item, answerIndex) {
      if (item.isHidden) {
        item.isHidden = false
        let answerIndexToRemove = this.answers.findIndex(answer => answer?.answer === item.answers[0].text)
        this.answers[answerIndexToRemove] = null
        this.checkCanValidate()
      }
      this.selectedAnswer = item
      this.answerIndex = answerIndex
    },
    addAnswer (questionIndex) {
      if (!this.selectedAnswer) return
      const answerSelected = this.selectedAnswer.answers[0].text
      const element = this.$refs['answer-'+ questionIndex][0]
      // this.answers = this.answers.filter(answer => answer.answer !== answerSelected)
      if (this.answers[questionIndex]) this.choices[questionIndex].isHidden = false
      this.answers[questionIndex] = {
        questionIndex,
        answer: answerSelected,
        element
      }
      this.choices[this.answerIndex].isHidden = true
      this.selectedAnswer = null
      this.checkCanValidate()
    },
    checkCanValidate () {
      let isValid = true
      this.answers.forEach(answer => {
        if (!answer) isValid = false
      })
      this.canValidate = isValid
    }
  },
}
</script>
